import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserChat } from "../types/auth";
import { Chat, Mensagem } from "../types/chat";



export interface ActiveChat extends Chat { }

export interface ListUsers extends Chat { }

export interface ChatReducer {
    mensagens: Mensagem[],
    chats: Chat[],
    chatlist: Chat[],
    activeChat: ActiveChat | null
    listusers: ListUsers[],
    isLoading: boolean,
};
export let initialState: ChatReducer = {
    mensagens: [],
    chats: [],
    chatlist: [],
    activeChat: null,
    listusers: [],
    isLoading: false
}

export const chatSlice = createSlice({
    name: 'chat:mensagens',
    initialState,
    reducers: {
        addChat(state, action: PayloadAction<Chat>) {
            state.chats.push(action.payload);
        },
        setChats(state, action: PayloadAction<Chat[]>) {
            state.chats = action.payload;
        },
        removeChats(state) {
            state.chats = [];
        },
        addChatList(state, action: PayloadAction<Chat>) {
            state.chatlist.push(action.payload);
        },
        setChatsList(state, action: PayloadAction<Chat[]>) {
            state.chatlist = action.payload;
        },
        removeChatsList(state) {
            state.chatlist = [];
        },
        addMensagem(state, action: PayloadAction<Mensagem>) {
            state.mensagens.push(action.payload);
        },
        setMensagens(state, action: PayloadAction<any[]>) {
            state.mensagens = action.payload;
        },
        removeMensagens(state) {
            state.mensagens = [];
        },
        setActiveChat(state, action: PayloadAction<ActiveChat>) {
            state.activeChat = action.payload;
        },
        removeActiveChat(state) {
            state.activeChat = null;
        },
        setListUsers(state, action: PayloadAction<ListUsers[]>) {
            state.listusers = action.payload
        },
        removeListUsers(state) {
            state.listusers = [];
        },
        removeAllInChats(state) {
            state.activeChat = null;
            state.chatlist = [];
            state.chats = [];
            state.listusers = [];
            state.mensagens = [];
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    addChat, setChats, removeChats,
    addChatList, setChatsList, removeChatsList,
    addMensagem, setMensagens, removeMensagens,
    setActiveChat, removeActiveChat,
    setListUsers, removeListUsers,
    removeAllInChats, setLoading
} = chatSlice.actions

export default chatSlice.reducer

