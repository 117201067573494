


import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import chatSlice from './chat';
import authSlice from './auth';

const persistConfig = {
    key: 'root',
    storage,
}

const combineReducer = combineReducers({
    chat: chatSlice,
    auth: authSlice
})

const persistedReducer = persistReducer(persistConfig, combineReducer)

export const store = configureStore({
    reducer: persistedReducer,
    // middleware: [thunkMiddleware]
});

export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;