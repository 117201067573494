import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Provider } from 'react-redux';
import { store } from './redux';
import { AlertProvider } from './context/AlertContext';
import { PocketProvider } from './context/AuthContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <ChakraProvider >
      <Provider store={store}>
        <AlertProvider>
          <PocketProvider>
            <App />
          </PocketProvider>
        </AlertProvider>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);
