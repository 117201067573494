import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthLogin, UserAuthenticated } from "../types/auth";

export interface AuthReducer {
    auth: AuthLogin | null,
    user: UserAuthenticated | null,
};
export let initialState: AuthReducer = {
    auth: null,
    user: null
}

export const authSlice = createSlice({
    name: 'auth:login',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserAuthenticated>) {
            state.user = action.payload;
        },
        removeUser(state) {
            state.user = null;
        },
        login(state, action: PayloadAction<AuthLogin>) {
            state.auth = action.payload;
        },
        logout(state) {
            state.auth = null;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setUser,
    removeUser,
    login,
    logout
} = authSlice.actions

export default authSlice.reducer

