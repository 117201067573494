import { Center, Spinner } from '@chakra-ui/react';
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {

  const Chat = lazy(() => import('./pages/chat'));
  const Painel = lazy(() => import('./pages/painel'));
  const Cadastro = lazy(() => import('./pages/cadastro'));
  const Login = lazy(() => import('./pages/login'));

  return (
    <Router>
      <Suspense fallback={<Center
        w={`100vw`}
        h={`100vh`}
      >
        <Spinner size={`xl`} color='cyan.500' />
      </Center>}>
        <Routes>
          <Route path='/'>
            <Route path="/" element={<Login />} />
            <Route path="/painel" element={<Painel />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/chat" element={<Chat />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
