import { jwtDecode } from "jwt-decode";
import ms from "ms";
import PocketBase, { AuthModel, BaseAuthStore } from "pocketbase";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useInterval } from "usehooks-ts";
import { AuthLogin, UserChat } from "../types/auth";
import { Chat, Mensagem } from "../types/chat";

const BASE_URL = "https://docx-chat.pockethost.io";
const fiveMinutesInMs = ms("5 minutes");
const twoMinutesInMs = ms("2 minutes");

export interface PocketContext<T> {
    token: BaseAuthStore['token'] | null,
    register: (props: AuthLogin) => Promise<T>,
    login: (props: AuthLogin) => Promise<T>,
    logout: () => void,
    pb: PocketBase | null,
    user: AuthModel | null,
    activeChat: Chat | null,
    mensagens: Mensagem[],
    isCallNotificationSound: boolean,
    onPlayOrMutedSound: () => void,
    setActiveChat: React.Dispatch<Chat | null>,
    setMensagens: React.Dispatch<Mensagem[]>,
    chatList: UserChat[],
    setChatList: React.Dispatch<UserChat[]>,
    list: Chat[],
    setList: React.Dispatch<Chat[]>,
    isLoading: boolean,
    onInitLoading: () => void,
    onEndLoading: () => void,

}

const PocketContext = createContext<PocketContext<any>>({
    token: null,
    register: async () => { },
    login: async () => { },
    logout: async () => { },
    pb: null,
    user: null,
    mensagens: [],
    setMensagens: () => { },
    activeChat: null,
    setActiveChat: () => { },
    chatList: [],
    setChatList: () => { },
    isLoading: false,
    onInitLoading: () => { },
    onEndLoading: () => { },
    isCallNotificationSound: true,
    onPlayOrMutedSound: () => { },
    list: [],
    setList: () => { }
});

export const PocketProvider: React.FC<any> = ({ children }) => {
    const pb = useMemo(() => new PocketBase(BASE_URL), []);
    const storageIsPlayNotify = '@isplaysoundnotification';
    let isPlaySoundNotification: string | null | NotifyBoolean = localStorage.getItem(storageIsPlayNotify);

    type NotifyBoolean = 'true' | 'false';
    let notifyBoolean = {
        'true': true,
        'false': false
    }
    useEffect(() => {
        if (!isPlaySoundNotification) {
            localStorage.setItem(storageIsPlayNotify, 'true');
            setIsCallNotificationSound(true)
        } else {
            //@ts-ignore
            setIsCallNotificationSound(notifyBoolean[isPlaySoundNotification])
        }
    }, [])

    const [token, setToken] = useState(pb.authStore.token);
    const [user, setUser] = useState(pb.authStore.model);
    const [activeChat, setAC] = useState<Chat | null>(null);
    const [mensagens, setMsgs] = useState<Mensagem[]>([]);
    const [chatList, setChatList] = useState<UserChat[]>([]);
    const [list, setList] = useState<Chat[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCallNotificationSound, setIsCallNotificationSound] = useState<boolean>(true);

    const onPlayOrMutedSound = () => {
        setIsCallNotificationSound(!isCallNotificationSound)
        localStorage.setItem(storageIsPlayNotify, `${!isCallNotificationSound}`)
    };

    const onInitLoading = () => setIsLoading(true);
    const onEndLoading = () => setIsLoading(false);

    const setActiveChat = useCallback((chat: Chat | null) => {
        setAC(chat)
    }, []);

    const setMensagens = useCallback((msgs: Mensagem[]) => {
        setMsgs(msgs)
    }, [])

    useEffect(() => {
        return pb.authStore.onChange((token, model) => {
            setToken(token);
            setUser(model);
        });
    }, []);

    const register = useCallback(async ({ email, password }: AuthLogin) => {
        return await pb
            .collection("users")
            .create({ email, password, passwordConfirm: password });
    }, []);

    const login = useCallback(async ({ email, password }: AuthLogin) => {
        return await pb.collection("users").authWithPassword(email, password);
    }, []);

    const logout = useCallback(() => {
        pb.authStore.clear();
    }, []);

    const refreshSession = useCallback(async () => {
        if (!pb.authStore.isValid) return;
        const decoded = jwtDecode(token);
        const tokenExpiration = decoded?.exp as number;
        const expirationWithBuffer = (decoded?.exp as number + fiveMinutesInMs) / 1000;
        if (tokenExpiration < expirationWithBuffer) {
            await pb.collection("users").authRefresh();
        }
    }, [token]);


    useEffect(() => {
        
    }, [])

    useInterval(refreshSession, token ? twoMinutesInMs : null);

    return (
        <PocketContext.Provider
            value={{
                isLoading,
                onInitLoading,
                onEndLoading,
                register,
                login,
                logout,
                user,
                token,
                pb,
                activeChat,
                setActiveChat,
                chatList,
                setChatList,
                mensagens,
                setMensagens,
                list,
                setList,
                isCallNotificationSound,
                onPlayOrMutedSound
            }}
        >
            {children}
        </PocketContext.Provider>
    );
};

export const usePocket = () => useContext(PocketContext);